<template>
  <div>
    <p class="title">Articles</p>

    <article-item
      v-for="(article, index) in articlesPageState.articles"
      :key="index"
      :article="article"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import articleProvider from '@/providers/article'

const ArticleItem = () => import('@/components/articles/ArticleItem')

export default {
  components: {
    'article-item': ArticleItem
  },

  props: {},

  data() {
    return {}
  },

  computed: {
    ...mapState('articleStore', ['articlesPageState'])
  },

  created() {
    this.getArticles()
  },

  methods: {
    ...mapMutations('articleStore', ['setArticlesPageState']),
    ...mapMutations('articleStore', ['setArticle']),

    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage
    },

    getArticles() {
      let self = this
      self.isSearching = true

      articleProvider.methods
        .getArticles(
          this.articlesPageState.searchTerm,
          this.articlesPageState.page,
          this.articlesPageState.itemsPerPage
        )
        .then(response => {
          if (response.status === 200) {
            if (response.data.MorePages === 0) {
              self.articlesPageState.reachedEnd = true
            }

            let articles = []
            if (self.articlesPageState.page > 1) {
              articles = self.articlesPageState.articles
            }

            for (let i = 0; i < response.data.Results.length; i++) {
              articles.push(response.data.Results[i])
            }

            this.articlesPageState.articles = articles
            this.setArticlePageSTate(this.articlesPageState)
          }
        })
        .catch(e => {
          // console.log(error.response)
        })
        .finally(() => {
          self.isSearching = false
        })
    },

    searchArticles() {
      // Reset pageState
      this.articlesPageState.reachedEnd = false
      this.articlesPageState.page = 1
      this.articlesPageState.articles = []

      this.setArticlePageSTate(this.articlesPageState)

      this.getArticles()
    }
  }
}
</script>

<style></style>
